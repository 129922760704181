import React, { useEffect, useState } from "react";
import defaault from '../../Assets/images/cancer.jpeg';
import success from '../../Assets/images/success.webp';
import Logo from '../../Assets/images/vithyoulogo.svg';
import UserIcon from '../../Assets/images/user-reg.png';
import { Link } from 'react-router-dom';

const RegistrationLanding  = () => {
    const [isHidden, setIsHidden] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setIsHidden(true);
          } else {
            setIsHidden(false);
          }
        };
    
        window.addEventListener("scroll", handleScroll);
    
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);
    return (
            <div className='reg-full-cont'>
                <div className={`reg-header ${isHidden ? "bg-white" : ""}`}>
                    <div className='container'>
                        <div className="header">
                            <div className="head-left">
                                <img src={Logo} alt='header-logo' className='reg-lan-logo' />
                            </div>
                            <div className="head-right">
                                <p>Need Help?</p>
                                <p className='call'>Call</p>
                                <p>+91 97-38-38-38-38</p>
                                {/* <button className="desk-login">Login</button> */}
                                <button className="mobile-login"><img src={UserIcon} alt="" title=""></img></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-wrapper'>
                    <section className='top-register-sec'>
                        <div className='container'>
                            <div className='main-cont-header'>
                                <h1 className='main-cont-head-top'>Partner With Vithou <br></br> and Monitor your health</h1>
                                <Link to="/partner/new"><button className="register-btn">Register Your Diagnostic Center</button></Link>
                            </div>
                        </div>
                    </section>
                    <section className='reg-doc-sec'>
                        <div className='container'>
                            <div className='register-doc-req'>
                                <div className='top-reg-head'>
                                    <h2 className="card-head">Get Started - It only takes 10 minutes</h2>
                                    <p className="card-para">Please be ready with the following for a smooth registration</p>                 
                                </div>
                                <div className='bot-req-docs'>
                                    <div className="card-content">
                                        <div className="card-cont-left">
                                            <div className="item">
                                                <img src={success} className="icon" />
                                                <p className="success">PAN Card</p>
                                            </div>
                                            <div className="item">
                                                <img src={success} className="icon" />
                                                <p>GST Number if applicable</p>
                                                <span>
                                                    Request a GST <a href="#">Apply Here</a>
                                                </span>
                                            </div>
                                            <div className="item">
                                                <img src={success} className="icon" />
                                                <p>Bank account details</p>
                                            </div>
                                        </div>
                                        <div className="card-cont-right">
                                            <div className="item">
                                                <img src={success} className="icon" />
                                                <p>Menu details and one dish image</p>
                                            </div>
                                            <div className="item">
                                                <img src={success} className="icon" />
                                                <p>FSSAI license</p>
                                                <span>
                                                    Don't have an FSSAI license? <a href="#">Apply Here</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className='vithyou-partner-sec'>
                        <div className='container'>
                            <div className='partner-with-vithyou'>
                                <div className='features-head'>
                                    <span>Why should you partner with Zomato?</span>
                                </div>
                                <div className='features'>
                                    <div className='customers'>
                                        <img src='https://b.zmtcdn.com/data/o2_assets/546cc9c88402b3bc3b2980137b157d0a1724921700.png' />
                                        <h2>Attract new customers</h2>
                                        <p>Reach the millions of people ordering on Zomato</p>
                                    </div>
                                    <div className='delivery'>
                                        <img src='https://b.zmtcdn.com/data/o2_assets/91fdfc547485f6d3c09ba49a925d5ccd1724921680.png' />
                                        <h2>Doorstep delivery convenience</h2>
                                        <p>Easily get your orders delivered through our trained delivery partners</p>
                                    </div>
                                    <div className='support'>
                                        <img src='https://b.zmtcdn.com/data/o2_assets/373ac53fbbcc7eb345fac0ab74df1d921724921640.png' />
                                        <h2>Hotline support</h2>
                                        <p>On-call support for any issues or growth consultations</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                
            </div>
    )
}
export default RegistrationLanding;