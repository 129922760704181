import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Backbtn from '../../Assets/images/Back-btn.png';
import authService from '../../../Services/authService';

const AddAddress = () => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const token = authService.getCurrentUsertoken();

  const [formData, setFormData] = useState({
    building_name: '',
    state_id: '',
    city_id: '',
    area: '',
    phone_number: '',
    landmark: '',
    pincode: '',
    user_id: authService.getCurrentuser().id,
  });

  useEffect(() => {
    loadStates();
  }, []);

  const loadStates = () => {
    fetch(authService.getApiUrl() + 'states', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setStates(result.data);
      })
      .catch((err) => console.log(err.message));
  };


  
  const loadCities  = (stateId) => {
    fetch(authService.getApiUrl() + 'statebasedCity', {
      method: 'POST',
      body: JSON.stringify({ state_id: stateId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCities(result.data);
      })
      .catch((err) => console.log(err.message));
  };
  
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' }); // Clear error on input
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.building_name) newErrors.building_name = "Building Name is required";
    if (!formData.area) newErrors.area = "Area is required";
    if (!formData.landmark) newErrors.landmark = "Landmark is required";
    if (!formData.state_id) newErrors.state_id = "Please select a State";
    if (!formData.city_id) newErrors.city_id = "Please select a City";
    if (!formData.pincode) newErrors.pincode = "Pincode is required";
    if (!formData.phone_number) newErrors.phone_number = "Phone Number is required";
    else if (!/^\d{10}$/.test(formData.phone_number)) newErrors.phone_number = "Invalid Phone Number";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    fetch(authService.getApiUrl() + 'AddAddress', {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then(() => window.history.back())
      .catch((err) => console.log(err.message));
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFormData({ ...formData, state_id: stateId, city_id: '' });
    loadCities(stateId);
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setFormData({ ...formData, city_id: cityId, area_id: '' });
  };


  
  return (
    <div className='homepageWrapper'>
      <div className='client-container'>
        <div className='add-member-cont'>
          <div className='back-heading-add'>
            <div className='back-btn-add'>
              <Link to='#' onClick={() => window.history.back()}>
                <img src={Backbtn} alt='back-btn' title='back-btn' />
              </Link>
            </div>
            <h1>Add Address</h1>
          </div>
          <form className="needs-validation clearfix" onSubmit={handleSubmit}>
            <div className="addtest-fields mb-3">
              <div className='flex-cont'>

                {/* Building Name */}
                <div className="half-cont">
                <div className="form-group">
                  <label>Building Name, Flat Number <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" name="building_name" value={formData.building_name} onChange={handleChange} />
                  {errors.building_name && <span className="error-message">{errors.building_name}</span>}
                </div>
                </div>

                {/* Area */}
                <div className="half-cont">
                <div className="form-group">
                  <label>Area, Street, Sector <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" name="area" value={formData.area} onChange={handleChange} />
                  {errors.area && <span className="error-message">{errors.area}</span>}
                </div>
                </div>

                {/* Landmark */}
                <div className="half-cont">
                <div className="form-group">
                  <label>Landmark <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" name="landmark" value={formData.landmark} onChange={handleChange} />
                  {errors.landmark && <span className="error-message">{errors.landmark}</span>}
                </div>
                </div>

                {/* State */}
                <div className="half-cont">
                <div className="form-group">
                  <label>State <span className="text-danger">*</span></label>
                  <select className="form-control" name="state_id" value={formData.state_id} 
                  onChange={handleStateChange}>
                    <option value="">--Select--</option>
                    {states.map((item) => (
                      <option value={item.id} key={item.id}>{item.state_name}</option>
                    ))}
                  </select>
                  {errors.state_id && <span className="error-message">{errors.state_id}</span>}
                </div>
                </div>

                {/* City */}
                <div className="half-cont">
                <div className="form-group">
                  <label>City <span className="text-danger">*</span></label>
                  <select className="form-control" name="city_id" value={formData.city_id}
                   onChange={handleCityChange}
                    disabled={!formData.state_id}>
                    <option value="">--Select--</option>
                    {cities.map((item) => (
                      <option value={item.id} key={item.id}>{item.city_name}</option>
                    ))}
                  </select>
                  {errors.city_id && <span className="error-message">{errors.city_id}</span>}
                </div>
                </div>

                {/* Phone Number */}
                <div className="half-cont">
                <div className="form-group">
                  <label>Phone Number <span className="text-danger">*</span></label>
                  <input className="form-control" type="text" name="phone_number" value={formData.phone_number} onChange={handleChange} />
                  {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                </div>
                </div>

                {/* Pincode */}
                <div className="half-cont">
                <div className="form-group">
                  <label>Pincode <span className="text-danger">*</span></label>
                  <input className="form-control" type="number" name="pincode" value={formData.pincode} onChange={handleChange} />
                  {errors.pincode && <span className="error-message">{errors.pincode}</span>}
                </div>
                </div>

                {/* Submit Button */}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">Add</button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>

    
    </div>
  );
};

export default AddAddress;
