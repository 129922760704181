
import React, { useContext, useEffect, useState } from 'react';
import servicecard from '../../Assets/images/service-card.png';
import docimg from '../../Assets/images/doc.png';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';


const PartnerRegistration = () => {

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [file, setFile] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const token = authService.getCurrentUsertoken();

  const [formData, setFormData] = useState({
    name: '',
    state_id: '',
    city_id: '',
    area_id: '',
    description: '',
    alias: '',
    landmark: '',
    fax: '',
    latitude: '',
    longitude: '',
    manager_phone: '',
    owner_phone: '',
    Is_Active: '',
    address: '',
    pan_number:'',
    gst_number:'',
    hospital_licence_id:'',
    pan_image:'',
    gst_image:'',
    hospital_licence_image:'',
    formData:'',
    pincode:'',
  });

  useEffect(() => {
    loadStates();

  }, []);

  const loadStates = () => {

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + 'states');
    //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: 'GET', // Set method to GET
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => {
        if (res.status === 401) {
          // If status is 401, redirect to the login page
          authService.clientlogout();
          window.location.href = "/login";
        } else {
          return res.json(); // Proceed with parsing JSON if the response is okay
        }
      })
      .then((result) => {
        setStates(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setFile(e.target.files[0]);
  // };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    if (file) {
      // Update the specific field in the formData state
      setFormData({
        ...formData,
        [name]: file, // Use the input field's name to set the correct value
      });
    }
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFormData({ ...formData, state_id: stateId, city_id: '', area_id: '' });
    loadCities(stateId);
  };

  const loadCities = (stateId) => {

    fetch(authService.getApiUrl() + 'statebasedCity', {
      method: 'POST',
      body: JSON.stringify({ state_id: stateId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCities(result.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setFormData({ ...formData, city_id: cityId, area_id: '' });
    loadAreas(cityId);
  };

  const handleAreaChange = (event) => {
    const areaId = event.target.value;
    setFormData({ ...formData, area_id: areaId });
  };

  const loadAreas = (cityId) => {


    fetch(authService.getApiUrl() + 'citybasedArea', {
      method: 'POST',
      body: JSON.stringify({ city_id: cityId }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setAreas(result.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  const convertImageToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      // Resolve the base64 string when the file is successfully read
      reader.onload = () => resolve(reader.result);
      
      // Reject in case of any errors
      reader.onerror = error => reject(error);
    });
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formData);
      const ValidationErrors=ValidateForm(formData);
      setErrors(ValidationErrors);

      if(Object.keys(ValidationErrors).length ===0){
      const base64String = formData.org_image?await convertImageToBase64(formData.org_image):"";
      fetch(authService.getApiUrl() + 'organizations', {
      method: 'POST', // Change method to PUT
      body: JSON.stringify({ 
        name: formData.name,
        image:base64String,
        state_id:formData.state_id,
        city_id:formData.city_id,
        area_id:formData.area_id,
        Is_Active:"I",
        landmark:formData.landmark,
        manager_phone:formData.manager_phone,
        owner_phone:formData.owner_phone,
        address:formData.address,
        pincode:formData.pincode,
        pan_number:formData.pan_number,
        pan_image:formData.pan_image?await convertImageToBase64(formData.pan_image):"",
        gst_number:formData.gst_number,
        gst_image:formData.gst_image?await convertImageToBase64(formData.gst_image):"",
        hospital_licence_id:formData.hospital_licence_id,
        hospital_licence_image:formData.hospital_licence_image?await convertImageToBase64(formData.hospital_licence_image):"",
      }), // Include the data to be updated in the body
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
      })
      .then((res) => res.json())
      .then((result) => {
          //setFormData(result.data); // Set the form data with the result
          navigate(`/admin/profile`);
          //console.log(result.data)
      })
      .catch((err) => {
          console.log(err.message); // Handle any errors
      });
    }else{
      console.log("form failed to submit due to errors");
    }

  };

  const ValidateForm = () => {
    const errors = {};
    const nameRegex = /^[a-z A-Z]+$/;
    const phoneRegex = /^[6-9][0-9]{9}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  
    if (!formData.name) {
      errors.name = 'Organization name is required';
    } else if (formData.name.length < 4) {
      errors.name = 'Organization name must be at least 4 characters';
    } else if (!nameRegex.test(formData.name)) {
      errors.name = 'Organization name can only contain letters';
    }
    
    if (!formData.manager_phone) {
      errors.manager_phone = 'Manager phone number is required';
    } else if (!phoneRegex.test(formData.manager_phone)) {
      errors.manager_phone = 'Phone number must start with 6-9 and contain exactly 10 digits';
    }
  
    if (!formData.owner_phone) {
      errors.owner_phone = 'Owner phone number is required';
    } else if (!phoneRegex.test(formData.owner_phone)) {
      errors.owner_phone = 'Phone number must start with 6-9 and contain exactly 10 digits';
    }
  
    if (!formData.state_id) {
      errors.state_id = 'State should be selected'; 
    }
    if (!formData.city_id) {
      errors.city_id = 'City should be selected';
    }
    if (!formData.area_id) {
      errors.area_id = 'Area should be selected';
    }
    if (!formData.landmark) {
      errors.landmark = 'Landmark is required';
    }
    if (!formData.address) {
      errors.address = 'Address is required';
    }
    if (!formData.pincode) {
      errors.pincode = 'Pincode is required';
    }
    
    if (!formData.pan_number) {
      errors.pan_number = 'PAN Number is required';
    } else if (!panRegex.test(formData.pan_number)) {
      errors.pan_number = 'Invalid PAN Number format';
    }
    
    if (!formData.gst_number) {
      errors.gst_number = 'GST Number is required';
    } else if (!gstRegex.test(formData.gst_number)) {
      errors.gst_number = 'Invalid GST Number format';
    }
    
    if (!formData.hospital_licence_id) {
      errors.hospital_licence_id = 'Hospital Licence ID is required';
    }
    
    if (!formData.pan_image) {
      errors.pan_image = 'PAN card image is required';
    }
    if (!formData.gst_image) {
      errors.gst_image = 'GST image is required';
    }
    if (!formData.hospital_licence_image) {
      errors.hospital_licence_image = 'Hospital licence image is required';
    }
    
    return errors;
  };
  
  

  return (
    <>
      <div className='Registartion1-conatiner'>
        <div className='direction-row'>
          <div className='direction-col RightContainer-scrollable'>
            <div className='Rleft-conatiner'>
              <h3 className=''>Complete your registration</h3>
              <hr className='hr'></hr>
              <div className="restaurant-info">
                <div className="icon-container">
                  <img src={servicecard} alt="Restaurant Icon" className="icon" />
                </div>
                <div className="info-text">
                  <h3>Diagnostic Centre Information</h3>
                  <p>Name, location and contact number</p>
                  {/* <a href="#" className="continue-btn">Continue</a> */}
                </div>
                {/* <a href="#" className="continue-btn">Continue</a> */}
              </div>

            </div>
            <div className='RLeft-bottom'>
              <div className="registration-container">
                <div className="registration-header">
                  <img src={docimg} alt="Verification illustration" className="registration-image" />
                </div>
                <div className="registration-content">
                  <h3>Please be ready with the following for a smooth registration</h3>
                  <ul className="registration-list">
                    <li><span className="checkmark">✔</span> PAN card</li>
                    <li className='direction-col'>
                      <div>
                        <span className="checkmark">✔</span> Hospital license</div>
                      {/* <div>
                        <span className="subtext">Don't have a FSSAI license? <a href="#">Apply here</a></span>
                      </div> */}
                    </li>
                    {/* <li><span className="checkmark">✔</span> Bank account details</li> */}
                    {/* <li><span className="checkmark">✔</span> Menu details and one dish image</li> */}
                    <li className='direction-col'>
                      <div>
                        <span className="checkmark">✔</span> GST number, if applicable </div>
                      <div>
                        <span className="subtext">Not GST registered? <a href="#">Apply here</a></span>
                      </div>

                    </li>
                  </ul>

                </div>
              </div>

            </div>
          </div>
          <div className='direction-col RightContainer-scrollable'>
            <form className="needs-validation clearfix" onSubmit={handleSubmit} >
              <h2 className='form-head1'>Diagnostic Centre information</h2>
              <div className="RightContainer-form-container">
                <div className="form-group">
                  <label>
                    Diagnostic Centre Name
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"

                    value={formData.name}
                    onChange={handleInputChange}
                  // required
                  />
                  {errors.name && (<span className="error-message">{errors.name}</span>)}

                  <div className='upload-img-cont' style={{ marginTop: '5px' }}>
                    <span>Upload Image <span className="text-danger">*</span></span>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      name="org_image"
                      onChange={handleImageChange}
                      className="form-control"

                    />
                    <small className="text-secondary">
                      Recommended image size is <b>300 × 300 px</b>
                    </small>
                  </div>
                  <div className="form-group">
                    <label>
                      Manager Phone Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="manager_phone"
                      value={formData.manager_phone}
                      onChange={handleInputChange}
                      placeholder=""
                    // required
                    />
                    {errors.manager_phone && (<span className="error-message">{errors.manager_phone}</span>)}
                  </div>
                  <div className="form-group">
                    <label>
                      Owner Phone Number
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="owner_phone"
                      value={formData.owner_phone}
                      onChange={handleInputChange}

                    // required
                    />
                    {errors.owner_phone && (<span className="error-message">{errors.owner_phone}</span>)}
                  </div>
                </div>
              </div>


              <div className="RightContainer-form-container">
                <div className="RightContainer-form-section">
                  <label className="RightContainer-section-title">Diagnostic Center Address Details</label>
                  <p>Address details are basis the Diagnostic center location mentioned below</p>
                  <div className='d-flex'>
                    <div className="form-group">
                      <label>
                        State <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select form-control"
                        name="state_id"
                        // required
                        value={formData.state_id}
                        onChange={handleStateChange}
                      >
                        <option value="" key="0">--Select--</option>
                        {states.map((item, i) => (
                          <option value={item.id} key={i}>{item.state_name}</option>
                        ))}
                      </select>
                      {errors.state_id && (<span className="error-message">{errors.state_id}</span>)}
                    </div>
                    <div className="form-group">
                      <label>
                        City <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select form-control"
                        name="city_id"
                        // required
                        value={formData.city_id}
                        onChange={handleCityChange}
                        disabled={!formData.state_id}
                      >
                        <option value="" key="0">--Select--</option>
                        {cities.map((item, i) => (
                          <option value={item.id} key={i}>{item.city_name}</option>
                        ))}
                      </select>
                      {errors.city_id && (<span className="error-message">{errors.city_id}</span>)}
                    </div>

                  </div>
                  <div className='d-flex'>
                    <div className="form-group">
                      <label>
                        Area <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-select form-control"
                        name="area_id"
                        value={formData.area_id}
                        onChange={handleAreaChange}
                      // required
                      >
                        <option value="" key="0">--Select--</option>
                        {areas.map((item, i) => (
                          <option value={item.id} key={i}>{item.area_name}</option>
                        ))}
                      </select>
                      {errors.area_id && (<span className="error-message">{errors.area_id}</span>)}
                    </div>
                    <div className="form-group">
                      <label>
                        Land Mark <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="landmark"
                        value={formData.landmark}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.landmark && (<span className="error-message">{errors.landmark}</span>)}
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className="form-group">
                      <label>
                        Address <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.address && (<span className="error-message">{errors.address}</span>)}
                    </div>
                    <div className="form-group">
                      <label>
                        Pincode <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.pincode && (<span className="error-message">{errors.pincode}</span>)}
                    </div>
                  </div>

                  <div className='d-flex'>
                    <div className="form-group">
                      <label>
                        PAN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="pan_number"
                        value={formData.pan_number}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.pan_number && (<span className="error-message">{errors.pan_number}</span>)}
                    </div>
                    <div className='upload-img-cont' style={{ marginTop: '5px' }}>
                    <span>Upload Image <span className="text-danger">*</span></span>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      name="pan_image"
                      onChange={handleImageChange}
                      className="form-control"

                    />
                    <small className="text-secondary">
                      Recommended image size is <b>300 × 300 px</b>
                    </small>
                    {errors.pan_number && (<span className="error-message">{errors.pan_number}</span>)}
                  </div>
                    
                  </div>

                  <div className='d-flex'>
                  <div className="form-group">
                      <label>
                        GST Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="gst_number"
                        value={formData.gst_number}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.gst_number && (<span className="error-message">{errors.gst_number}</span>)}
                    </div>
                    <div className='upload-img-cont' style={{ marginTop: '5px' }}>
                    <span>Upload Image <span className="text-danger">*</span></span>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      name="gst_image"
                      onChange={handleImageChange}
                      className="form-control"

                    />
                    <small className="text-secondary">
                      Recommended image size is <b>300 × 300 px</b>
                    </small>
                    {errors.gst_image && (<span className="error-message">{errors.gst_image}</span>)}
                  </div>
                  </div>

                  <div className='d-flex'>
                    <div className="form-group">
                      <label>
                        Hospital Licence ID <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="hospital_licence_id"
                        value={formData.hospital_licence_id}
                        onChange={handleInputChange}
                      // required
                      />
                      {errors.hospital_licence_id && (<span className="error-message">{errors.hospital_licence_id}</span>)}
                    </div>
                    <div className='upload-img-cont' style={{ marginTop: '5px' }}>
                    <span>Upload Image <span className="text-danger">*</span></span>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      name="hospital_licence_image"
                      onChange={handleImageChange}
                      className="form-control"

                    />
                    <small className="text-secondary">
                      Recommended image size is <b>300 × 300 px</b>
                    </small>
                    {errors.hospital_licence_image && (<span className="error-message">{errors.hospital_licence_image}</span>)}
                  </div>
                  </div>

                </div>

              </div>
              <input className="okay-button" type="submit" value="Okay"/>
            </form>
          </div>

        </div>
      </div>

    </>
  )
}

export default PartnerRegistration
