import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Backbtn from '../../Assets/images/Back-btn.png';
import ProfileImg from '../../Assets/images/Mask group.png';
import Close from '../../Assets/images/close.png';
import authService from '../../../Services/authService';

const Appointment = () => { 
    const [activeTab, setActiveTab] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [appointmentData, setAppointmentData] = useState([]);
    const location = useLocation(); 
    let Organization_id = location.pathname.split('/')[3];
    const token = authService.getCurrentUsertoken();

    useEffect(() => {
        loadTimeSlots();
    }, []); 
    
    const loadTimeSlots = () => {
        const url = new URL(authService.getApiUrl() + 'currentweekslots');
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ 
                organization_id: Organization_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
            },
        })
            .then((res) => {
                if (res.status === 401) {
                    authService.clientlogout();
                    window.location.href = "/login";
                } else {
                return res.json();
                }
            })
            .then((result) => {
                if (result && result.data) {
                    setAppointmentData(result.data);
                }
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    const openTab = (tabName) => {
        setActiveTab(tabName);
    };

    const closeTab = () => {
        setActiveTab(null);
    };

    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(null); // Reset selected time when date changes
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    };

    const handleSubmit = () => {
        if (selectedDate && selectedTime) {
            // console.log("Selected Date:", selectedDate);
            // console.log("Selected Time:", selectedTime);
            //alert(`Appointment confirmed for ${selectedDate} at ${selectedTime}`);
            let responce= authService.AppointmentSlotsSetup(selectedDate, selectedTime);
            //console.log(responce);
            if(responce){
                //console.log(responce);
                window.location.href = "/lab-tests/cart/"+Organization_id;
            }
        } else {
            alert("Please select a date and time slot.");
        }
    };

    return (
        <div className='homepageWrapper'>
            <div className='client-container'>
                <div className='add-member-cont'>
                    <div className='back-heading-add'>
                        <div className='back-btn-add'>
                            <Link to='#' onClick={() => window.history.back()}><img src={Backbtn} alt='back-btn' title='back-btn'></img></Link>
                        </div>
                        <h1>Select Appointment Time</h1>
                    </div>
                    <div className="appointment-picker">
                        <div className="date-picker">
                            {appointmentData.map(({ day, date }) => (
                                <button
                                    key={date}
                                    className={`date-btn ${selectedDate === date ? "selected" : ""}`}
                                    onClick={() => handleDateClick(date)}
                                >
                                    {day.slice(0, 3)}<br />
                                    {date.split("-")[2]}
                                </button>
                            ))}
                        </div>
                        {selectedDate && (
                            <div className="time-slot-picker">
                                {appointmentData
                                    .find(({ date }) => date === selectedDate)
                                    ?.times.map(({ time, disabled }) => (
                                        <button
                                            key={time}
                                            className={`${selectedTime === time ? "selected" : ""} ${disabled ? "disabled-btn disabled" : "time-slot-btn"}`}
                                            onClick={() => !disabled && handleTimeClick(time)}
                                            disabled={disabled}
                                        >
                                            {time}
                                        </button>
                                    )) || <p>No slots available for this date</p>}
                            </div>
                        )}
                    </div>

                </div>
            </div>
            <div className='add-mem-btn-cont'>
                <div className="client-container">
                    <button onClick={handleSubmit} className='add-mem-btn'>
                        Confirm Appointment
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Appointment;
