import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import RichTextEditor from 'react-rte';
import { useParams } from "react-router";
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useNavigate } from 'react-router-dom';
import Breadcrumb from "../Breadcrumb/Breadcrumb"
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"; // Optional: Theme

const AddTimeslot = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({

    user_id: '',

  });
  const { organization } = useContext(OrganizationContext);

  const [users, setUsers] = useState([]);


  // errors
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (organization) {
      loadUsers();
    }
  }, [organization]);


  const loadUsers = () => {
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + 'orguserslist');
    //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: 'POST', // Set method to GET
      body: JSON.stringify({
        organization_id: organization,

      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => {
        if (res.status === 401) {
          // If status is 401, redirect to the login page
          authService.logout();
          window.location.href = "/admin";
        } else {
          return res.json(); // Proceed with parsing JSON if the response is okay
        }
      })
      .then((result) => {
        setUsers(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const ValidateForm = () => {
    const errors = {};

    if (!formData.user_id) {
      errors.user_id = 'User Id is required';
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const ValidateErrors = ValidateForm(formData);
    setErrors(ValidateErrors);

    if (Object.keys(ValidateErrors).length === 0) {
      const token = authService.getCurrentadmin().token;
      fetch(authService.getApiUrl() + 'adddeliveryagent ', {
        method: 'POST',
        body: JSON.stringify({
          user_id: formData.user_id,
          organization_id: organization,
          type:'delivery',
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          navigate(`/admin/cdeliveryagents`);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  return (
    <div className='main-container'>
      <div className='container-fluid'>
        <Breadcrumb />
        <div className='category-table'>
          <div className='flex-cont add-cat'>
            <div className='heading'>
              <h3>Add Member</h3>
            </div>
          </div>
          <div className="card">
            <div className="card-body custom-edit-service">
              <form className="needs-validation clearfix" onSubmit={handleSubmit}>
                <div className="addtest-fields mb-3">
                  <div className="flex-cont">
                    <div className="half-cont">
                      <div className="form-group">
                        <label>
                          Select Member <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-select form-control"
                          name="user_id"
                          // required
                          value={formData.user_id}
                          onChange={handleInputChange}
                        >
                          <option value="">--Select--</option>
                          {users.map((item, i) => (
                            <option value={item.user_id} key={i}>{item.name} - {item.role_name}</option>
                          ))}
                        </select>
                        {errors.user_id && <span className='errors' >{errors.user_id}</span>}
                      </div>
                    </div>


                  </div>

                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    name="form_submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTimeslot;
