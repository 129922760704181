import React, { useState, useEffect, useContext } from 'react';
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import authService from '../../../Services/authService';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import { useParams, useNavigate } from 'react-router-dom';

const RolesPermissions = () => {
    const { organization } = useContext(OrganizationContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [modules, setModules] = useState([]);
    const [formData, setFormData] = useState({ role_name: '' });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');


    useEffect(() => {
        if (organization && id) {
            loadModules();
        } else {
            setMessage("");
        }
    }, [organization, id]);

    const loadModules = async () => {
        setLoading(true);
        const token = authService.getCurrentadmin().token;

        try {
            const url = `${authService.getApiUrl()}roles/${id}?organization_id=${organization}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${token}`,
                },
            });

            const result = await response.json();
            console.log(result);

            if (result.modules && result.modules.length > 0) {
                const updatedModules = result.modules.map(module => ({
                    ...module,
                    permissions: { add: module.permissions.add || 0, edit: module.permissions.edit || 0, delete: module.permissions.delete || 0, view: module.permissions.view || 0 },
                    sub_menu: module.sub_menu.map(sub => ({
                        ...sub,
                        permissions: { add: sub.permissions.add || 0, edit: sub.permissions.edit || 0, delete: sub.permissions.delete || 0, view: sub.permissions.view || 0 },
                    })),
                }));
                setModules(updatedModules);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    role_name: result.role_name || '', 
                    is_active: result.is_active === 'A' ? 'A' : 'I', 
                }));
            } else {
                setModules([]);
                setMessage('No data available for this role.');
            }
        } catch (err) {
            console.error(err.message);
            setModules([]);
            setMessage('Failed to load modules. Please try again.');
        } finally {
            setLoading(false);
        }
    };

   

    const handleCheckboxChange = (moduleId, permission) => {
        setModules(prevModules =>
            prevModules.map(module => {
                if (module.module_id === moduleId) {
                    return {
                        ...module,
                        permissions: {
                            ...module.permissions,
                            [permission]: module.permissions[permission] === 1 ? 0 : 1, 
                        },
                    };
                }
                return module;
            })
        );
    };

    const handleSubCheckboxChange = (moduleId, subId, permission) => {
        setModules(prevModules =>
            prevModules.map(module => {
                if (module.module_id === moduleId) {
                    return {
                        ...module,
                        sub_menu: module.sub_menu.map(sub => {
                            if (sub.sub_module_id === subId) {
                                return {
                                    ...sub,
                                    permissions: {
                                        ...sub.permissions,
                                        [permission]: sub.permissions[permission] === 1 ? 0 : 1, 
                                    },
                                };
                            }
                            return sub;
                        }),
                    };
                }
                return module;
            })
        );
    };
    const handleStatusChange = (event) => {
        const isChecked = event.target.checked; // Get the checked state of the checkbox
        setFormData({
            ...formData,
            is_active: isChecked ? 'A' : 'I', // Set 'A' for active, 'I' for inactive
        });
    };
    
    

    const handleSaveChanges = async () => {
        const token = authService.getCurrentadmin().token;
        const dataToSave = {
            role_name: formData.role_name,
            is_active: formData.is_active,
            organization_id: organization, 
            modules: modules.map(module => ({
                module_id: module.module_id,
                permissions: {
                    add: module.permissions.add ? 1 : 0,
                    edit: module.permissions.edit ? 1 : 0,
                    view: module.permissions.view ? 1 : 0,
                    delete: module.permissions.delete ? 1 : 0,
                },
                sub_menu: module.sub_menu.map(sub => ({
                    sub_module_id: sub.sub_module_id,
                    permissions: {
                        add: sub.permissions.add ? 1 : 0,
                        edit: sub.permissions.edit ? 1 : 0,
                        view: sub.permissions.view ? 1 : 0,
                        delete: sub.permissions.delete ? 1 : 0,
                    },
                })),
            })),
        };

        console.log('Data to save:', dataToSave); 

        try {
            const response = await fetch(`${authService.getApiUrl()}roles/${id}/permissions`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(dataToSave),
            });

            if (response.ok) {
                setMessage('Permissions updated successfully!');
                //navigate('/admin/user-management');
                window.history.back()
            } else {
                const errorData = await response.json(); 
                console.error('Error data:', errorData); 
                setMessage('Error updating permissions: ' + (errorData.errors ? JSON.stringify(errorData.errors) : errorData.message));
            }
        } catch (err) {
            console.error('Error:', err);
            setMessage('Error updating permissions.');
        }
    };

    
    return (
        <div className="main-container">
            <div className="container-fluid">
                <Breadcrumb />
                <div className="category-table user-add-cont">
                    <div className="flex-cont add-cat">
                        <div className="heading">
                            <h3>Add Role & Permissions</h3>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body custom-edit-service">
                            <form className="needs-validation clearfix">
                                <div className="addtest-fields mb-3">
                                    <div className="flex-cont">
                                        <div className="half-cont">
                                            <div className="form-group">
                                                <label>
                                                    Role Name
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="role_name"
                                                    id="role_name"
                                                    required
                                                    value={formData.role_name}
                                                    onChange={(e) => setFormData({ ...formData, role_name: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="full-cont special-case-cont">
                                    <div className="form-group">
                                        <label className="custom-checkbox">
                                            <input
                                                type="checkbox"
                                                name="is_active"
                                                checked={formData.is_active === 'A'} 
                                                onChange={handleStatusChange} 
                                            />
                                            <span className="checkmark"></span>
                                            <label>Status</label>
                                        </label>
                                    </div>
                                </div>
                            </form>
                            {message && <div className="alert alert-info">{message}</div>}
                        </div>
                    </div>

                    <div className="flex-cont user-add-cont">
                        <div className="heading">
                            <h4>Permissions <span className="text-danger">*</span></h4>
                        </div>
                    </div>

                    <div className="hospitals-table rights-table">
                        <div className="all-rights">
                            {loading ? (
                                <div>Loading modules...</div>
                            ) : modules.length > 0 ? (
                                modules.map(module => (
                                    <>
                                    <div className="single-right" key={module.module_id}>
                                        <div className="column-div right-name">
                                            <div className='main_module'>{module.name}</div>
                                        </div>
                                           
                                                {module.name === 'Dashboard' ? (
                                                    <div className="column-div right-check-box" key="view" style={{ marginRight: '20px' }}>
                                                        <label className="custom-checkbox">
                                                            <input
                                                                type="checkbox"
                                                                checked={module.permissions.view} // Should be a boolean
                                                                onChange={() => handleCheckboxChange(module.id, 'view')}
                                                            />
                                                            <span className="checkmark"></span>
                                                            <span className="check-text" style={{ marginLeft: '8px' }}>
                                                                View
                                                            </span>
                                                        </label>
                                                    </div>
                                                ) : (
                                                    module.sub_menu.length === 0 ? (
                                                        ['view', 'add', 'edit', 'delete'].map(permission => (
                                                            <div className="column-div right-check-box" key={permission} style={{ marginRight: '20px' }}>
                                                                <label className="custom-checkbox">
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={module.permissions[permission]} // Ensure it's a boolean
                                                                        onChange={() => handleCheckboxChange(module.module_id, permission)}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                    <span className="check-text" style={{ marginLeft: '8px' }}>
                                                                        {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        ))
                                                    ) : null // If the module has submenus, show nothing
                                                    
                                                )}
                                                

                                               
                                    </div>
                                    

                                        {module.sub_menu.length > 0 && (
                                            <>
                                                {module.sub_menu.map(sub => (
                                                    <div className="single-right" >
                                                        <div className="column-div right-name">
                                                          <div>{sub.name}</div>
                                                        </div>
                                                        <>
                                                            {['view', 'add', 'edit', 'delete'].map(permission => (
                                                                <div className="column-div right-check-box" key={permission} style={{ marginRight: '20px' }}>
                                                                    <label className="custom-checkbox">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={sub.permissions ? sub.permissions[permission] === 1 : false}
                                                                            onChange={() => handleSubCheckboxChange(module.module_id, sub.sub_module_id, permission)}
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                        <span className="check-text" style={{ marginLeft: '8px' }}>
                                                                            {permission.charAt(0).toUpperCase() + permission.slice(1)}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </>
                                                        
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    
                                    </>
                                ))
                            ) : (
                                <div>No modules available for this organization ID.</div>
                            )}
                        </div>
                    </div>

                    <div className="save-cont">
                        <div className="right-cont">
                            <button type='button' onClick={() => window.history.back()} className="cancel-btn">Cancel</button>
                            <button type="button" className="save-btn" onClick={handleSaveChanges}>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RolesPermissions;
