import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import Backbtn from "../../Assets/images/Back-btn.png";
import SearchIcon from "../../Assets/Icons/SearchIcon";
import Share from "../../Assets/images/share.png";
import Defaultimg from '../../Assets/images/default1.png';
import MoreIcon from "../../Assets/Icons/MoreIcon";
import HospitalImg from '../../Assets/images/recommended-center1.png';
import authService from '../../../Services/authService';


const Orders = () => {
  const [isOpen, setIsOpen] = useState(false);
  const token = authService.getCurrentUsertoken();
  const [orders, setOrders] = useState([]);

    useEffect(() => {
        loadOrders();
    }, []); 
    

    const loadOrders = () => {

      const url = new URL(authService.getApiUrl() + 'myorders' );
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ 
          user_id: authService.getCurrentuser().id,
        }), // Include the data to be updated in the body
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
        },
      })
        .then((res) => {
          if (res.status === 401) {
              authService.clientlogout();
              window.location.href = "/login";
          } else {
          return res.json();
          }
        })
        .then((result) => {
          //const data = result.data;
          setOrders(result.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="homepageWrapper BookmarkWrapper">
      <div className="client-container">
        <div className="bookmark-cont">
          <div className="back-heading-add">
            <div className="back-btn-add">
              <Link to='#' onClick={() => window.history.back()}>
                <img src={Backbtn} alt="back-btn" title="back-btn" />
              </Link>
            </div>
            <h1>Your Orders</h1>
          </div>
          <div className="order-cont">
              <div className="orders-search">
                <div className="searchbtn"><SearchIcon /></div>
                <input className="form-control" name="search" id="order_search" placeholder="Search by hospital or test name"></input>
              </div>
              <div className="all-orders-cont">

              {orders.map((data, index) => {
                const orderDate = data.orderdate 
                    ? new Date(data.orderdate.replace(" ", "T")) 
                    : null;
            
                const formattedDate = orderDate && !isNaN(orderDate)
                    ? orderDate.toLocaleString('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      }).replace(',', '')
                    : "Date not available";
                return(
                <div className="each-order-cont">
                  <div className="order-hospital">
                   <Link to={'/lab-tests/'+encodeURIComponent(data.orders_info.organization_name.replace(/ /g, '-'))+'-'+data.orders_info.organization_id}>
                      <div className="hos-img">
                        <img src={authService.getApiDomainUrl()+data.orders_info.image} alt="" title=""></img>
                        <div className="Address-cont">
                            <h3 className='client-name'>{data.orders_info.organization_name}</h3>
                            <span>{data.orders_info.area_name}, {data.orders_info.city_name}</span>
                        </div>
                      </div>
                    </Link>
                    <div className="right-actions">
                      <div className="actions" onClick={handleToggle}>
                        <MoreIcon />
                      </div>
                      <ul className={`orders-dropdown ${isOpen ? "open" : ""}`}>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Share Hospital</Link></li>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Order details</Link></li>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Delete this order</Link></li>
                      </ul>
                    </div>
                  </div>
                <Link to={'/profile/order-details/'+data.order_id}>
                  <div className="order-details">
                    <div className="test-det">
                      {data.orders_items.map((item, itemIndex) => (
                          <div className="test-item" key={itemIndex}>
                              <div className="name-test">
                                  <span className="item-count">{item.quantity}</span>
                                  <span className="cross">×</span>
                                  <h3>{item.test_name}</h3>
                              </div>
                          </div>
                      ))}

                    </div>
                  </div>

                  <div className="order-place-details">
                    <div className="placed-det">
                      <span className="placed-on">Order placed on {formattedDate}</span>
                      <span className="oreder-status completed"><strong>Delivered</strong></span>
                    </div>
                    <div className="order-price"><h4>₹{data.total_price}</h4></div>
                  </div>
                  </Link>
                </div>
              )})}

                {/* <div className="each-order-cont">
                  <div className="order-hospital">
                    <Link className="hospital-det" to="/">
                      <div className="hos-img">
                        <img src={HospitalImg} alt="" title=""></img>
                        <div className="Address-cont">
                            <h3 className='client-name'>Nizampet</h3>
                            <span>Hyderabad, Telangana</span>
                        </div>
                      </div>
                    </Link>
                    <div className="right-actions">
                      <div className="actions" onClick={handleToggle}>
                        <MoreIcon />
                      </div>
                      <ul className={`orders-dropdown ${isOpen ? "open" : ""}`}>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Share Hospital</Link></li>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Order details</Link></li>
                        <li><Link to="/"><img src={Share} alt="" title=""></img>Delete this order</Link></li>
                      </ul>
                    </div>
                  </div>
                  <div className="order-details">
                    <div className="test-det">
                      <div className="test-item">
                        <div className="name-test">
                          <span className="item-count">1</span>
                          <span className="cross">×</span>
                          <h3>Thyroid Test</h3>
                        </div>
                      </div>
                      <div className="test-item">
                        <div className="name-test">
                          <span className="item-count">2</span>
                          <span className="cross">×</span>
                          <h3>Thyroid Test</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-place-details">
                    <div className="placed-det">
                      <span className="placed-on">Order placed on 09 Dec, 1:06PM</span>
                      <span className="oreder-status canceled"><strong>Canceled</strong></span>
                    </div>
                    <div className="order-price"><h4>₹215.23</h4></div>
                  </div>
                </div> */}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
