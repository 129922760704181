import React, { useState, useEffect } from 'react';
import authService from '../../../Services/authService';

const OrderInfo = ({ data }) => {
    const [OrderInf, setData] = useState({}); // ✅ Initialize as an object

    useEffect(() => {
        loadDeliveryAgentOrdersInfo();
    }, []);

    const loadDeliveryAgentOrdersInfo = () => {
        const token = authService.getCurrentadmin().token; // Retrieve auth token

        // Construct the request URL
        const url = new URL(authService.getApiUrl() + 'agentorderviewidbased');

        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ order_id: data.order_id }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            if (res.status === 401) {
                authService.logout();
                window.location.href = "/admin";
            } else {
                return res.json();
            }
        })
        .then((result) => {
            if (result?.data?.length > 0) {
                setData(result.data[0]); // ✅ Set first object from array
            } else {
                setData({});
            }
        })
        .catch((err) => console.error("Fetch Error:", err));
    };

    return (
        <div style={{ width: '100%', margin: '20px 0' }}>
            <table
                key={OrderInf?.order_id}
                border="1"
                cellSpacing="0"
                cellPadding="10"
                style={{ width: '100%', textAlign: 'left', marginBottom: '20px' }}
            >
                <thead>
                    <tr>
                        <td colSpan="4"><strong>Invoice Number :</strong> {OrderInf?.invoice_number || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td><strong>Patient Name</strong></td>
                        <td><strong>Test Name</strong></td>
                        <td><strong>Quantity</strong></td>
                        <td><strong>Preparation</strong></td>
                    </tr>
                </thead>
                <tbody>
                    {/* ✅ Render Individual Tests */}
                    {OrderInf?.orders_items?.products?.map((items, index) => (
                        <tr key={`product-${index}`}>
                            <td>{items.family_member_name || 'N/A'}</td>
                            <td>{items.test_name || 'N/A'}</td>
                            <td>{items.quantity || 'N/A'}</td>
                            <td>{items.preparation || 'N/A'}</td>
                        </tr>
                    ))}

                    {/* ✅ Render Packages */}
                    {OrderInf?.orders_items?.packages?.map((pitems, index) => (
                        <React.Fragment key={`package-${index}`}>
                            <tr>
                                <td colSpan="2"><strong>Package Name: </strong>{pitems.test_name || 'N/A'}</td>
                                <td colSpan="2"><strong>Patient Name: </strong>{pitems.family_member_name || 'N/A'}</td>
                            </tr>
                            <tr>
                                <td colSpan="2"><strong>Test Name</strong></td>
                                <td><strong>Quantity</strong></td>
                                <td><strong>Preparation</strong></td>
                            </tr>
                            {pitems.lab_tests?.map((psubitems, subIndex) => (
                                <tr key={`lab-test-${index}-${subIndex}`}>
                                    <td colSpan="2">{psubitems.test_name || 'N/A'}</td>
                                    <td>1</td>
                                    <td>{psubitems.preparation_description || 'N/A'}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}

                    {/* ✅ Slot Info */}
                    <tr>
                        <td colSpan="4"><strong>Slot Date :</strong> {OrderInf?.slot_date || 'N/A'}</td>
                    </tr>
                    <tr>
                        <td colSpan="4"><strong>Slot Time :</strong> {OrderInf?.slot_time || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default OrderInfo;
