import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space,Select} from 'antd';
import EditIcon from '../EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import HospitalImage from '../../Assets/images/hospital.png'
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useParams } from "react-router";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function

const DeliveryAgent = () => {

  const permissions = usePermissions(); // Get global permissions
  const navigate = useNavigate();
  const params = useParams();
  const [Data, setData] = useState([]);
  const { organization } = useContext(OrganizationContext);
  //const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [formData, setFormData] = useState({
    search: '',
  });
  const [status, setStatus] = useState("Status");

  useEffect(() => {
    if (organization) {
      loadDeliveryAgents();
    }
  }, [organization]);


  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDelete = (id) => {
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
    fetch(authService.getApiUrl() + "organizations/" + id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        alert(result.data);
        loadDeliveryAgents();
      })
      .catch((err) => {
      });
  }


  const loadDeliveryAgents = () => {
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + 'deliveryagentslist');
    //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: 'POST', // Set method to GET
      body: JSON.stringify({
        organization_id: organization,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => {
        if (res.status === 401) {
          // If status is 401, redirect to the login page
          authService.logout();
          window.location.href = "/admin";
        } else {
          return res.json(); // Proceed with parsing JSON if the response is okay
        }
      })
      .then((result) => {
        //setData(result.data); // Set the category data in state
        setData(result.data.map((item) => ({ ...item, key: item.id })));
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };


  const handleStatusChange = (value) => {
    const token = authService.getCurrentadmin().token; 

    fetch(authService.getApiUrl() + "agentstatuschange", {
      method: "PUT", 
      body: JSON.stringify({
        status: value,
        id: selectedRowKeys,
        organization_id:organization,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`, 
      },
    })
      .then((res) => res.json())
      .then((result) => {
        loadDeliveryAgents();
      })
      .catch((err) => {
        console.log(err.message); 
      });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("Selected Row Keys:", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const columns = [

    {
      title: "User Name",
      dataIndex: "user_name",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (text) => (
          <span className={text === 'A' ? 'status-btn-green' : 'status-btn-red' }>
              {text === 'A' ? 'Active' : 'Inactive'}
          </span>
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <Link to={"/admin/deliveryagents/" + record.date} className="me-1 btn btn-sm bg-success-light">
    //         {hasPermission(permissions, 'deliveryagents', 'edit') &&
    //           <Button
    //             type="text"
    //             icon={<EditIcon />}

    //           />
    //         }
    //       </Link>
    //       <Button
    //           type="text"
    //           icon={<img src={Trash} alt="Delete" style={{ width: 18, height: 18 }} />}
    //           onClick={() => handleDelete(record.id)}
    //       />
    //     </Space>
    //   ),
    // },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + 'searchdeliveryagent', {
      method: 'POST', // Change method to PUT
      body: JSON.stringify({
        search: formData.search,
        organization_id: organization,
        type:'delivery',
      }), // Include the data to be updated in the body
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <div className='main-container'>
        <div className='container-fluid'>
          <Breadcrumb />
          <div className='category-table'>
            <div className='flex-cont add-cat'>
              <div className='heading'>
                <h3>Delivery Agents</h3>
              </div>

            </div>
            <div className='flex-cont just-cont-right bord-botm'>
              <div className='filter-search'>
                <input
                  type='text'
                  className='category-search'
                  placeholder='Search by date'
                  onChange={handleInputChange}
                  name="search"
                  id="search"
                />
                <Button type='submit' onClick={handleSubmit}>Search</Button>

                <div className='add-btn'>
                  {hasPermission(permissions, 'cdeliveryagents', 'add') &&
                    <Link to="/admin/cdeliveryagents/add-member">Add</Link>
                  }
                </div>
                <Select
                                  defaultValue={status}
                                  style={{ width: 150, marginLeft: 10,float:'right' }}
                                  onChange={handleStatusChange}
                              >
                                  <option value="" selected='selected'>--Select--</option>
                                  <option value="A">Active</option>
                                  <option value="I">Inactive</option>
                              </Select>
              </div>
            </div>
            <div className='hospitals-table' id='hospitals_table'>
              <Table
                rowSelection={rowSelection}
                dataSource={Data}
                columns={columns}
                pagination={{ pageSize: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryAgent;
