import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Table, Button, Space, Select } from 'antd';
import EditIcon from '../EditIcon';
import Trash from '../../Assets/images/recycle-bin.png';
import HospitalImage from '../../Assets/images/hospital.png'
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import { OrganizationContext } from '../../../Services/OrganizationContext';
import authService from '../../../Services/authService';
import { useParams } from "react-router";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { usePermissions } from '../../../Services/PermissionsContext'; // Import the permissions context
import { hasPermission } from '../../../Services/checkPermissions';   // Import the helper function
import ViewIcon from '../Menumanagement/View';
import OrderInfo from './OrderInfo';
import Close from '../../Assets/images/close.png';

const Laboratory = () => {

  const permissions = usePermissions(); // Get global permissions
  const navigate = useNavigate();
  const params = useParams();
  const [Data, setData] = useState([]);
  const { organization } = useContext(OrganizationContext);
  //const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRecord, setselectedRecord] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    search: '',
  });
  const [status, setStatus] = useState("Status");
  const Packagedata = [];

  useEffect(() => {
    if (organization) {
      loadorders();
    }
  }, [organization]);


  const stripHtmlTags = (str) => {
    if (!str) return '';
    return str.replace(/<[^>]*>/g, '');
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const showModal = (record) => {
    setselectedRecord(record);
    setIsModalVisible(true);
  };

  const closeModal = (record) => {
    setIsModalVisible(false);
    setselectedRecord(record);
  };


  // const handleDelete = (id) => {
  //   const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization
  //   fetch(authService.getApiUrl() + "organizations/" + id, {
  //     method: "DELETE",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-type": "application/json; charset=UTF-8",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       alert(result.data);
  //       loadDeliveryAgents();
  //     })
  //     .catch((err) => {
  //     });
  // }


  const loadorders = () => {
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    // Construct the URL with the organization_id as a query parameter
    const url = new URL(authService.getApiUrl() + 'laboratorylist');
    //url.searchParams.append('organization_id', organization); // Append the organization_id as a query parameter

    fetch(url, {
      method: 'POST', // Set method to GET
      body: JSON.stringify({
        organization_id: organization,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Include Authorization header with Bearer token if required
      },
    })
      .then((res) => {
        if (res.status === 401) {
          // If status is 401, redirect to the login page
          authService.logout();
          window.location.href = "/admin";
        } else {
          return res.json(); // Proceed with parsing JSON if the response is okay
        }
      })
      .then((result) => {
        //setData(result.data); // Set the category data in state
        setData(result.data);
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };


  const handleStatusChange = (value) => {
    const token = authService.getCurrentadmin().token;

    fetch(authService.getApiUrl() + "agentstatuschange", {
      method: "PUT",
      body: JSON.stringify({
        status: value,
        id: selectedRowKeys,
        organization_id: organization,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        loadorders();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("Selected Row Keys:", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.date.length - b.date.length,
    },

    {
      title: "Tests",
      dataIndex: "orders_items",
      key: "tests",
      render: (orders_items) => {
        if (!orders_items) return "N/A";

        const testNames = Array.isArray(orders_items)
          ? orders_items.map((item) => `${item.test_name} - ${item.quantity}`).join(", ")
          : `${orders_items.test_name} - ${orders_items.quantity}`;

        return <span>{testNames}</span>;
      },
    },
    {
      title: "Slot Date",
      dataIndex: "slot_date",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Slot Time",
      dataIndex: "slot_time",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Order Status",
      dataIndex: "order_status",
      render: (_, record) => (
        <Space size="middle">

          <span className='status-btn-green' style={{ whiteSpace: 'nowrap', cursor: 'pointer' }} >{record.order_status}</span>

        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <Link to={"/admin/deliveryagents/" + record.date} className="me-1 btn btn-sm bg-success-light">
            {hasPermission(permissions, 'samplecollection', 'edit') && */}

          {/* {record.order_status_id === 2 && (
            <span className='status-btn-green' style={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => updateOrderStatus(record, 3)}>On The Way</span>
          )}
          {record.order_status_id === 3 && (
            <span className='status-btn-green' style={{ whiteSpace: 'nowrap', cursor: 'pointer' }} onClick={() => updateOrderStatus(record, 4)}>Sample Collected</span>
          )} */}
          <Button
            type="text"
            icon={<ViewIcon />}
            onClick={() => showModal(record)}
          />
          {hasPermission(permissions, 'laboratory', 'edit') &&
            <Link to={"/admin/laboratory/edit-order/" + record.id} className="me-1 btn btn-sm bg-success-light">
              <Button
                type="text"
                icon={<EditIcon />}
              />
            </Link>
          }
        </Space>
      ),
    },
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + 'searchdeliveryagent', {
      method: 'POST', // Change method to PUT
      body: JSON.stringify({
        search: formData.search,
        organization_id: organization,
      }), // Include the data to be updated in the body
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setData(result.data); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };


  const updateOrderStatus = (data, status) => {
    //event.preventDefault();
    const token = authService.getCurrentadmin().token; // Retrieve the token for authorizationauthorization // Retrieve the token for authorization

    fetch(authService.getApiUrl() + 'orderstracking', {
      method: 'POST', // Change method to PUT
      body: JSON.stringify({
        order_id: data.order_id,
        order_status_id: status,
      }), // Include the data to be updated in the body
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => res.json())
      .then((result) => {
        loadorders(); // Set the category data in state
      })
      .catch((err) => {
        console.log(err.message); // Handle any errors
      });

  };

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <div className='main-container'>
        <div className='container-fluid'>
          <Breadcrumb />
          <div className='category-table'>
            <div className='flex-cont add-cat'>
              <div className='heading'>
                <h3>Orders</h3>
              </div>
            </div>
            <div className='flex-cont just-cont-left bord-botm'>
              <div className='filter-search'>
                <input
                  type='text'
                  className='category-search'
                  placeholder='Search by date'
                  onChange={handleInputChange}
                  name="search"
                  id="search"
                />
                <Button type='submit' onClick={handleSubmit}>Search</Button>
              </div>
            </div>
            <div className='hospitals-table' id='hospitals_table'>
              <Table
                dataSource={Data}
                columns={columns}
                pagination={{ pageSize: 10 }}
              />
            </div>
          </div>
        </div>
        {isModalVisible && selectedRecord && (
          <div className='user-popup'>
            <div className="modal" onClick={closeModal}>
              <div className='modal-dialog modal-lg'>
                <div className="modal-content">
                  <div className='popup-headings'>
                    <h3>Package Details</h3>
                    <button type="button" className="close" onClick={closeModal}>
                      <img src={Close} alt="close" title="close" />
                    </button>
                  </div>
                  <div className='user-table first-table'>
                    <div className='user-role'>
                      <OrderInfo data={selectedRecord} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Laboratory;
