import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Backbtn from "../../Assets/images/Back-btn.png";
import Downloadinvoice from '../../Assets/images/report.png';
import Downloadfile from '../../Assets/images/file.png';
import Labicon from '../../Assets/images/Labicon.svg';
import authService from '../../../Services/authService';
import Vithyou from "../../../Admin/Assets/images/logo.png";


const OrderDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const token = authService.getCurrentUsertoken();
  const pathname = window.location.pathname;
  const order_id = pathname.substring(pathname.lastIndexOf("/") + 1);
  const [orderDetals, setOrderDetails] = useState({});

  const steps = [
    { label: "Order Placed", date: "Mon, 10th Jun" },
    { label: "Assigned To Delivery Agent", date: "Mon, 10th Jun" },
    { label: "On The Way", date: "Wed, 12th Jun" },
    { label: "Sample Collected", date: "Wed, 12th Jun" },
    { label: "Order Completed", date: "Wed, 12th Jun" },
  ];

  // Current progress: Update this to control progress
  const currentStep = 2; // 0-based index; 3 means all steps completed

  useEffect(() => {
    loadOrderDetails();
  }, []);

  const loadOrderDetails = () => {

    const url = new URL(authService.getApiUrl() + 'orderdetails/' + order_id);
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token}`, // Add Authorization header with Bearer token
      },
    })
      .then((res) => {
        if (res.status === 401) {
          authService.clientlogout();
          window.location.href = "/login";
        } else {
          return res.json();
        }
      })
      .then((result) => {
        //const data = result.data;
        // if (Array.isArray(result.data) && result.data.length > 0) {
        setOrderDetails(result.data); // Store the first object
        //}
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="homepageWrapper BookmarkWrapper">
      <div className="client-container">
        <div className="bookmark-cont">
          <div className="back-heading-add">
            <div className="back-btn-add">
              <Link to='#' onClick={() => window.history.back()}>
                <img src={Backbtn} alt="back-btn" title="back-btn" />
              </Link>
            </div>
            <h1>Order Details</h1>
          </div>
          <div className="order-details-page">
            <div className="hospital-card">
              <Link to="/">
                <h3>{orderDetals?.orders_info?.organization_name}</h3>
                <span className="address-hosp">{orderDetals?.orders_info?.area_name}, {orderDetals?.orders_info?.landmark}, {orderDetals?.orders_info?.city_name}</span>
              </Link>
              <div className="dow-files">
                <div className="dowload-invoice">
                  <img src={Downloadinvoice} alt="" title=""></img>
                  <h3>Download invoice</h3>
                </div>
                <div className="dowload-report">
                  <img src={Downloadfile} alt="" title=""></img>
                  <h3>Download Report</h3>
                </div>

              </div>
              <div className="order-progress tracking">
                <div className="order-details">
                  {/* <img
                    src={Vithyou}
                    alt="Product"
                    className="product-image"
                  /> */}
                  <div className="product-info">
                    <h4>Order Status</h4>
                  </div>
                </div>
                <div className="progress-bar-status">
                  {steps.map((step, index) => (
                    <div key={index} className="step">
                      {/* Add 'completed' class dynamically */}
                      <div
                        className={`dot ${index < orderDetals?.order_status_id ? "completed" : "dot"}`}
                      ></div>

                      <div
                        className={`line ${index < orderDetals?.order_status_id ? "completed" : "line"}`}
                      ></div>

                      <div className="step-details">
                        <p>{step.label}</p>
                        {/* <span>{step.date}</span> */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="hospital-card-items">
              <div className="order-headings">
                {/* <h4 className="order-status">This order was completed</h4> */}

                <h3>Your Order</h3>
              </div>
              <ul className="order-items">
                {orderDetals?.orders_items?.length > 0 ? (
                  orderDetals.orders_items.map((item, itemIndex) => (
                    <li className="order-item" key={itemIndex}>
                      <div className="left-title">
                        <h3 className="testname">{item.test_name}</h3>
                        <div className="items-count">
                          <div className="test-item">
                            <div className="name-test">
                              <img style={{ width: "20px", height: "20px" }} src={Labicon} alt="lab-icon" title="lab-icon"></img>
                              <span className="item-count">{item.quantity}</span>
                              <span className="cross">×</span>
                              <h3>₹{item.price}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="right-price">
                        <span className="price-det">₹{item.price}</span>
                      </div>
                    </li>
                  ))
                ) : (
                  <li>No order items available</li> // Fallback message if orders_items is empty
                )}
              </ul>
            </div>
            <div className="items-totals">
              <h4 className="total-cost">Item total</h4>
              <h4 className="total-cost">₹{orderDetals?.price}</h4>
            </div>
            <div className="taxes-list">
              <table>
                <tr>
                  <td>Taxes</td>
                  <td><span className="price">₹{orderDetals?.tax_amount}</span></td>
                </tr>
                <tr>
                  <td>Delivery Charges</td>
                  <td>
                    {/* <span className="price strinkeoff">₹34</span> */}
                    FREE</td>
                </tr>
                {/* <tr>
                  <td>Hospital charges</td>
                  <td><span className="price">₹20.00</span></td>
                </tr> */}
                {/* <tr>
                  <td>Platform fee</td>
                  <td><span className="price">₹10.00</span></td>
                </tr> */}
              </table>
            </div>
            <div className="grand-total">
              <h4 className="total-cost">Grand total</h4>
              <h4 className="total-cost">₹{orderDetals?.total_price}</h4>
            </div>
            <div className="main-order-details">
              <div className="order-headings">
                <h3>Order Details</h3>
              </div>
              <div className="paymemt-details">
                <div className="order-specifications">
                  <span className="pay-head">Invoice Number</span>
                  <span className="pay-des">{orderDetals?.invoice_number}</span>
                </div>
                <div className="order-specifications">
                  <span className="pay-head">Payment</span>
                  <span className="pay-des">Paid: Using Upi(₹{orderDetals?.total_price})</span>
                </div>
                <div className="order-specifications">
                  <span className="pay-head">Date</span>
                  <span className="pay-des"> {orderDetals?.orderdate
                    ? new Date(orderDetals.orderdate).toLocaleString('en-IN', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: true,
                      timeZone: 'Asia/Kolkata' // Adjusts to IST
                    })
                    : 'N/A'}</span>
                </div>
                <div className="order-specifications">
                  <span className="pay-head">Phone Number</span>
                  <span className="pay-des">{orderDetals?.address?.phone_number}</span>
                </div>

                <div className="order-specifications">
                  <span className="pay-head">Address</span>
                  <span className="pay-des">{orderDetals?.address?.building_name}, {orderDetals?.address?.landmark}, {orderDetals?.address?.area}, {orderDetals?.address?.pincode}</span>
                </div>

              </div>

            </div>

            <div className="contact-details">
              <p>call Pranaam Hospitals (+91 9014232643)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
