import React from "react";
import Vithyou from "../../../Admin/Assets/images/logo.png";
import { Link } from "react-router-dom";
import Backbtn from "../../Assets/images/Back-btn.png";
 
const AppStatus = () => {
  const steps = [
    { label: "Registered on", date: "Mon, 10th Jun" },
    { label: "Application viewed", date: "Mon, 10th Jun" },
    { label: "Under Review", date: "Wed, 12th Jun" },
    { label: "Approved", date: "Wed, 12th Jun" },
  ];
 
  // Current progress: Update this to control progress
  const currentStep = 2; // 0-based index; 3 means all steps completed
 
  const options = [
    { value: "Rock" },
    { value: "Paper" },
    { value: "Scissors" },
  ];
 
  return (
    <div className="homepageWrapper BookmarkWrapper">
      <div className="client-container">
        <div className="bookmark-cont">
          <div className="back-heading-add">
            <div className="back-btn-add">
              <Link to='#' onClick={() => window.history.back()}>
                <img src={Backbtn} alt="back-btn" title="back-btn" />
              </Link>
            </div>
            <h1>Application Status</h1>
          </div>
          <div className="order-progress">
            <div className="order-details">
              <img
                src={Vithyou}
                alt="Product"
                className="product-image"
              />
              <div className="product-info">
                <h4>Your Application is under Review</h4>
                <span>We'll Notify You once Approved.</span>
              </div>
            </div>
            <div className="progress-bar-status">
              {steps.map((step, index) => (
                <div key={index} className="step">
                  {/* Add 'completed' class dynamically */}
                  <div
                    className={`dot ${index < currentStep ? "completed" : "dot"}`}
                  ></div>
               
                    <div
                      className={`line ${index < currentStep ? "completed" : "line"}`}
                    ></div>
                 
                  <div className="step-details">
                    <p>{step.label}</p>
                    <span>{step.date}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default AppStatus;