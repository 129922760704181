// Loading.js
import React from 'react';
//import '../../Assets/css/Client.css'; // Optional if you want to style the loading screen
import { Link } from 'react-router-dom';
import EditIcon from '../../Assets/Icons/EditIcon';
import ProfileImg from '../../Assets/images/Mask group.png';
import LeftArrowTailIcon from '../../Assets/Icons/LeftArrowTailIcon';
import Myorders from '../../Assets/images/Myorders.png';
import Mywishlist from '../../Assets/images/Mywhishlist.png';
import Myprescription from '../../Assets/images/Myprescription.png';
import Yourlabtest from '../../Assets/images/Yourlabtest.png';
import Doctorconsultation from '../../Assets/images/Doctorconsultation.png';
import Address from '../../Assets/images/Address.png';
import Pillremainder from '../../Assets/images/Pillremainder.png';
import Invitefriends from '../../Assets/images/Invitefriends.png';
import Payment from '../../Assets/images/Payments.png';
import authService from '../../../Services/authService';
import logout from '../../Assets/images/exit.png';

const Profile = () => {

  const date = new Date(authService.getCurrentuser().created_at);

  // Extract the month and year
  const month = date.toLocaleString("default", { month: "long" }); // e.g., "November"
  const year = date.getFullYear(); // e.g., 2024

  const handleLogout=()=>{
    authService.clientlogout();  // Clear the session from auth service
    window.location.href = "/login";  // Redirect to login page or another route
  }

  return (
    <>
    <div className='clientWrapper'>
      <div className='client-profile'>
          <div className="client-container">
            <div className="profile-header">
              <Link to='#' onClick={() => window.history.back()}><LeftArrowTailIcon /></Link>
              <h2>PROFILE</h2>
            </div>
            <div className='inner-profile-cont'>
              <div className='client-profile-img'>
                <img src={ProfileImg} alt='client-img'></img>
              </div>
              <div className='client-profile-details'>
                <div className='prof-detail'>
                  <h3>{authService.getCurrentuser().user_name}</h3>
                  <p>******{authService.getCurrentuser().phone_number.slice(-4)}</p>
                  <span className='reg-date'>
                    Registered since {year} {month}
                  </span>
                </div>
                <div className='Profile-edit'>
                  <Link to='/'>
                    <EditIcon />
                  </Link>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='profile-menu'>
        <div className='client-container'>
          <ul className='profile-items'>
            <li className='profile-item'>
              <Link to='/profile/orders'>
                <img src={Myorders} alt='order-icon'></img>
                <h3>My Orders</h3>
              </Link>
            </li>
            <li className='profile-item'>
              <Link to='/profile/wishlist'>
                <img src={Mywishlist} alt='wishlist-icon'></img>
                <h3>My Wishlist</h3>
              </Link>
            </li>
            <li className='profile-item'>
              <Link to='/'>
                <img src={Myprescription} alt='prescription-icon'></img>
                <h3>My Prescription</h3>
              </Link>
            </li>
            {/* <li className='profile-item'>
              <Link to='/'>
                <img src={Yourlabtest} alt='lab-test-icon'></img>
                <h3>Your Lab Test</h3>
              </Link>
            </li>
            <li className='profile-item'>
              <Link to='/' alt="consultation-icon">
                <img src={Doctorconsultation} alt='consultation-icon'></img>
                <h3>Doctor Consultation</h3>
              </Link>
            </li> */}
            {/* <li className='profile-item'>
              <Link to='/'>
                <img src={Payment} alt='payment-icon'></img>
                <h3>Payment Methods</h3>
              </Link>
            </li> */}
            <li className='profile-item'>
              <Link to='/profile/address'>
                <img src={Address} alt='address-icon'></img>
                <h3>Your Addresses</h3>
              </Link>
            </li>
            {/* <li className='profile-item'>
              <Link to='/'>
                <img src={Pillremainder} alt='remainder-icon'></img>
                <h3>Pill Remainder</h3>
              </Link>
            </li> */}
            <li className='profile-item'>
              <Link to='/profile/add-member'>
                <img src={Invitefriends} alt='invite-icon'></img>
                <h3>Add Members</h3>
              </Link>
            </li>
            <li className='profile-item'>
              <Link to='#' onClick={handleLogout}>
                <img src={logout} alt='invite-icon'></img>
                <h3>Logout</h3>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      </div>
    </>
  );
};

export default Profile;
